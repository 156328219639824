import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../config/firebase";
import { useSongs } from "../../config/SongContext";
import { useUser } from "../../config/UserContext";
import { useFollowUnfollow } from "../../hooks/FollowUnfollow";
import UserPageSong from "../UserPageSong/UserPageSong";
import EditProfile from "../EditProfile";
import Edit from "../Icons/Edit";
import OverflowMenu from "../utils/OverflowMenu";
import LikedSong from "../LikedSong";
import UserListItem from "../UserListItem/UserListItem";
import { Helmet } from "react-helmet";
import styles from "./UserPage.module.scss";
import PostFormModal from "../PostFormModal/PostFormModal";

const UserPage = ({ openSongPage }) => {
  const { fetchLikedSongsByUser } = useUser();
  const [likedSongs, setLikedSongs] = useState([]);
  const { fetchSongsByUser, likeSong, unlikeSong } = useSongs();
  const [following, setFollowing] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [userSongs, setUserSongs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { handleFollow, handleUnfollow } = useFollowUnfollow();
  const [isFollowing, setIsFollowing] = useState(false);

  // Separate state variables for modals
  const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);
  const [isPostEditModalOpen, setIsPostEditModalOpen] = useState(false);
  const [postToEdit, setPostToEdit] = useState(null);
  const [activeTab, setActiveTab] = useState("Shared songs");
  const [isLikedSongsLoading, setIsLikedSongsLoading] = useState(true);

  const { userId } = useParams();

  const navigate = useNavigate();
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const isCurrentUser = currentUser && currentUser.uid === userId;

  const menuOptions1 = [
    {
      label: "Logout",
      handler: () => {
        auth.signOut().then(() => {
          navigate("/");
        });
      },
    },
  ];

  const fetchFollowers = useCallback(async () => {
    try {
      const followersSnapshot = await getDocs(
        collection(db, "users", userId, "followers")
      );
      const followersList = await Promise.all(
        followersSnapshot.docs.map(async (document) => {
          const uid = document.data().userId;
          const userDoc = await getDoc(doc(db, "users", uid));
          return {
            uid,
            displayName: userDoc.data()?.displayName || "Unknown User",
            avatar: userDoc.data()?.avatar || "/avatar.png",
          };
        })
      );
      setFollowers(followersList);
    } catch (error) {
      console.error("Error fetching followers: ", error);
    }
  }, [userId]);

  const fetchFollowing = useCallback(async () => {
    try {
      const followingSnapshot = await getDocs(
        collection(db, "users", userId, "following")
      );
      const followingList = await Promise.all(
        followingSnapshot.docs.map(async (document) => {
          const uid = document.data().userId;
          const userDoc = await getDoc(doc(db, "users", uid));
          return {
            uid,
            displayName: userDoc.data()?.displayName || "Unknown User",
            avatar: userDoc.data()?.avatar || "/avatar.png",
          };
        })
      );
      setFollowing(followingList);
    } catch (error) {
      console.error("Error fetching following: ", error);
    }
  }, [userId]);

  const fetchUserDataAndSongs = useCallback(async () => {
    try {
      if (!userId) {
        setIsLoading(false);
        return;
      }

      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      } else {
        setIsLoading(false);
        return;
      }

      const songs = await fetchSongsByUser(userId);
      setUserSongs(songs);

      if (currentUser) {
        const followingQuery = query(
          collection(db, "users", currentUser.uid, "following"),
          where("userId", "==", userId)
        );
        const followingSnapshot = await getDocs(followingQuery);
        setIsFollowing(!followingSnapshot.empty);
      }

      await fetchFollowers();
      await fetchFollowing();

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setIsLoading(false);
    }
  }, [fetchSongsByUser, userId, currentUser, fetchFollowers, fetchFollowing]);

  useEffect(() => {
    if (userId) {
      fetchUserDataAndSongs();
    }
  }, [userId, fetchUserDataAndSongs]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLikedSongsLoading(true);
      try {
        const songs = await fetchLikedSongsByUser(userId);
        setLikedSongs(songs);
      } catch (error) {
        console.error("Error fetching liked songs:", error);
        setLikedSongs([]); // Set to empty array on error
      } finally {
        setIsLikedSongsLoading(false);
      }
    };
    if (userId) {
      fetchData();
    }
  }, [userId, fetchLikedSongsByUser]);

  useEffect(() => {
    setActiveTab("Shared songs");
  }, [userId]);

  const handleLike = (songId) => {
    if (currentUser && currentUser.uid) {
      likeSong(songId, currentUser.uid);
    }
  };

  const handleUnlike = (songId) => {
    if (currentUser && currentUser.uid) {
      unlikeSong(songId, currentUser.uid);
    }
  };

  const handleFollowAndUpdate = async () => {
    await handleFollow();
    setIsFollowing(true);
    await fetchFollowers();
  };

  const handleUnfollowAndUpdate = async () => {
    await handleUnfollow();
    setIsFollowing(false);
    await fetchFollowers();
  };

  // Functions to open/close profile edit modal
  const openProfileEditModal = () => {
    setIsProfileEditModalOpen(true);
  };

  const closeProfileEditModal = () => {
    setIsProfileEditModalOpen(false);
  };

  // Functions to open/close post edit modal
  const openPostEditModal = (post) => {
    setPostToEdit(post);
    setIsPostEditModalOpen(true);
  };

  const closePostEditModal = () => {
    setIsPostEditModalOpen(false);
    setPostToEdit(null);
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loadingText}>Loading...</p>
      </div>
    );
  }

  if (!userData) {
    return <div>User not found</div>;
  }

  const defaultAvatarURL = "/avatar.png";

  const handleTabChange = (e, tabName) => {
    e.preventDefault();
    setActiveTab(tabName);
  };

  return (
    <>
      <Helmet>
        <title>
          {userData?.username || userData?.displayName || "Unknown User"} | Good
          Song Club
        </title>
        <meta
          name='description'
          content={`${
            userData?.username || userData?.displayName || "Unknown User"
          }'s profile on Good Song Club`}
        />
        <meta
          name='og:title'
          content={
            userData?.username || userData?.displayName || "Unknown User"
          }
        />
        <meta
          name='og:description'
          content={`${
            userData?.username || userData?.displayName || "Unknown User"
          }'s profile on Good Song Club`}
        />
        <meta name='og:image' content={userData?.avatar || defaultAvatarURL} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:title'
          content={
            userData?.username || userData?.displayName || "Unknown User"
          }
        />
        <meta
          name='twitter:description'
          content={`${
            userData?.username || userData?.displayName || "Unknown User"
          }'s profile on Good Song Club`}
        />
        <meta
          name='twitter:image'
          content={userData?.avatar || defaultAvatarURL}
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.userPageHeader}>
          <div className={styles.userPageHeaderInner}>
            <div className={styles.sidePanelHeadUserCluster}>
              <img
                className={styles.mainAvatar}
                src={userData?.avatar || defaultAvatarURL}
                alt={userData?.displayName || "Unknown User"}
                title={userData?.displayName || "Unknown User"}
              />
              <div className={styles.mainUserInfo}>
                <h3 className={styles.mainUserName}>
                  {userData?.username ||
                    userData?.displayName ||
                    "Unknown User"}
                </h3>
                <p className={styles.mainSubheader}>
                  {userSongs.length} songs shared
                </p>
              </div>
            </div>
            <div className={styles.sidePanelHeaderButtons}>
              {isCurrentUser ? (
                <div className={styles.headerButtons}>
                  <button
                    onClick={openProfileEditModal}
                    className={styles.sidePanelHeaderButton}
                  >
                    <Edit fill='#171717' />
                  </button>
                  <OverflowMenu options={menuOptions1} />
                </div>
              ) : "" }
            </div>
          </div>
        </div>
        {/* {currentUser ? (

        <div className={styles.bodyPanelHead}>
          <Link
            to='#'
            onClick={(e) => handleTabChange(e, "Shared songs")}
            className={
              activeTab === "Shared songs"
                ? `${styles.tab} ${styles.activeTab}`
                : styles.tab
            }
          >
            Shared songs
          </Link>

          
            <Link
              to='#'
              onClick={(e) => handleTabChange(e, "Following")}
              className={
                activeTab === "Following"
                  ? `${styles.tab} ${styles.activeTab}`
                  : styles.tab
              }
            >
              Following&nbsp;&nbsp;{following.length}
            </Link>

          
            <Link
              to='#'
              onClick={(e) => handleTabChange(e, "Followers")}
              className={
                activeTab === "Followers"
                  ? `${styles.tab} ${styles.activeTab}`
                  : styles.tab
              }
            >
              Followers&nbsp;&nbsp;{followers.length}
            </Link>

          
            <Link
              to='#'
              onClick={(e) => handleTabChange(e, "Likes")}
              className={
                activeTab === "Likes"
                  ? `${styles.tab} ${styles.activeTab}`
                  : styles.tab
              }
            >
              Likes
            </Link>
        </div>
          ) : (
            ""
          )} */}
        <div className={styles.bodyPanel}>
          <div className={styles.contentBox}>
            {activeTab === "Shared songs" &&
              userSongs
                .filter((song) => song.timestamp) // Ensure the song has a timestamp
                .sort(
                  (a, b) =>
                    b.timestamp.seconds - a.timestamp.seconds ||
                    b.timestamp.nanoseconds - a.timestamp.nanoseconds
                )
                .map((song) => (
                  <UserPageSong
                    key={song.id}
                    song={song}
                    currentUser={currentUser}
                    onLike={handleLike}
                    onUnlike={handleUnlike}
                    openSongPage={openSongPage}
                    openEditModal={openPostEditModal}
                  />
                ))}

            {activeTab === "Following" &&
              following.map((user) => (
                <div className={styles.followUser} key={user?.uid}>
                  {currentUser && <UserListItem user={user} />}
                </div>
              ))}

            {activeTab === "Followers" &&
              followers.map((user) => (
                <div key={user?.userId}>
                  {currentUser && <UserListItem user={user} />}
                </div>
              ))}
            {activeTab === "Likes" && (
              <div className={styles.likesArray}>
                {isLikedSongsLoading ? (
                  <p>Loading liked songs...</p>
                ) : likedSongs && likedSongs.length > 0 ? (
                  likedSongs
                    .filter((song) => song.likedAt)
                    .sort(
                      (a, b) =>
                        (b.likedAt?.seconds || 0) - (a.likedAt?.seconds || 0) ||
                        (b.likedAt?.nanoseconds || 0) -
                          (a.likedAt?.nanoseconds || 0)
                    )
                    .map((song) => (
                      <LikedSong
                        key={song.id}
                        song={song}
                        openSongPage={openSongPage}
                      />
                    ))
                ) : (
                  <p>No liked songs found.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <>
        <EditProfile
          show={isProfileEditModalOpen}
          onClose={closeProfileEditModal}
          userId={userId}
          fetchUserDataAndSongs={fetchUserDataAndSongs}
          currentUserDisplayName={currentUser?.displayName || "Unknown User"}
          currentAvatar={userData?.avatar || defaultAvatarURL}
        />
        <PostFormModal
          show={isPostEditModalOpen}
          onClose={closePostEditModal}
          isEditing={true}
          postToEdit={postToEdit}
        />
      </>
    </>
  );
};

export default UserPage;
