import React, { useState, useEffect } from "react";
import moment from "moment";
import styles from "./CommentList.module.scss";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog/ConfirmDeleteDialog";
import { Link } from "react-router-dom";
import OverflowMenu from "../utils/OverflowMenu";
import { useUser } from "../../config/UserContext";
import { useSongs } from "../../config/SongContext";
import LikeButton from "../LikeButton";
import DOMPurify from "dompurify";
import CommentForm from "../CommentForm/CommentForm";

const CommentList = ({
  comments,
  currentUser,
  onDelete,
  onDeleteReply,
  songId,
  handleUserPageNavFromSong,
}) => {
  const { likeComment, unlikeComment, addComment } = useSongs(); 
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedCommentIndex, setSelectedCommentIndex] = useState(null);
  const [selectedReplyIndex, setSelectedReplyIndex] = useState(null);
  const [userAvatars, setUserAvatars] = useState({});

  const { getUserDetailsById } = useUser();

  const handleLike = (songId, commentIndex, userId, replyIndex = null) => {
    likeComment(songId, commentIndex, userId, replyIndex);
  };

  const handleUnlike = (songId, commentIndex, userId, replyIndex = null) => {
    unlikeComment(songId, commentIndex, userId, replyIndex);
  };

  useEffect(() => {
    const fetchAvatars = async () => {
      const uniqueUserIds = [
        ...new Set(
          comments.flatMap((comment) => [
            comment.userId,
            ...(comment.replies || []).map((reply) => reply.userId),
          ])
        ),
      ];

      const avatars = {};

      for (const userId of uniqueUserIds) {
        try {
          const userDetails = await getUserDetailsById(userId);
          avatars[userId] = {
            avatar: userDetails?.avatar || defaultAvatar,
            displayName:
              userDetails?.displayName ||
              userDetails?.userName ||
              "Unknown User",
          };
        } catch (error) {
          console.error("Error fetching user details:", error);
          avatars[userId] = {
            avatar: defaultAvatar,
            displayName: "Unknown User",
          };
        }
      }

      setUserAvatars(avatars);
    };

    if (comments && comments.length > 0) {
      fetchAvatars();
    }
  }, [comments, getUserDetailsById]);

  const handleOpenConfirmDialog = (commentIndex, replyIndex = null) => {
    setSelectedCommentIndex(commentIndex);
    setSelectedReplyIndex(replyIndex);
    setShowConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCommentIndex !== null) {
      if (selectedReplyIndex !== null) {
        // Handle reply deletion
        if (typeof onDeleteReply === 'function') {
          onDeleteReply(selectedCommentIndex, selectedReplyIndex);
        } else {
          // Fall back to onDelete if onDeleteReply is not provided
          console.warn('onDeleteReply is not defined, falling back to onDelete');
          onDelete(selectedCommentIndex, selectedReplyIndex);
        }
      } else {
        // Handle comment deletion
        onDelete(selectedCommentIndex);
      }
    }
    setShowConfirmDialog(false);
    setSelectedCommentIndex(null);
    setSelectedReplyIndex(null);
  };

  const defaultAvatar = "../avatar.png";

  const renderText = (text) => {
    const sanitizedHTML = DOMPurify.sanitize(text);
    return (
      <p
        className={styles.commentText}
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      />
    );
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp && typeof timestamp.toDate === "function") {
      return moment(timestamp.toDate()).fromNow();
    } else if (timestamp instanceof Date) {
      return moment(timestamp).fromNow();
    } else if (timestamp && timestamp.seconds) {
      return moment.unix(timestamp.seconds).fromNow();
    } else {
      return "Unknown time";
    }
  };

  return (
    <div className={styles.comments}>
      Comments:
      <div className={styles.commentList}>
        {comments?.map((comment, index) => {
          const commentMenuOptions = [
            {
              label: "Delete",
              handler: () => handleOpenConfirmDialog(index),
              style: { color: "#EF4444" },
            },
          ];
          return (
            <div className={styles.container} key={index}>
              <div className={styles.mainComment}>
                <div className={styles.userCluster}>
                  <Link
                    to={`/user/${comment.userId}`}
                    className={styles.userLink}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUserPageNavFromSong(comment.userId);
                    }}
                  >
                    <img
                      src={userAvatars[comment.userId]?.avatar || defaultAvatar}
                      alt={
                        userAvatars[comment.userId]?.displayName ||
                        "Unknown User"
                      }
                      className={styles.avatar}
                    />
                    <div className={styles.infoStacked}>
                      <span className={styles.userName}>
                        {userAvatars[comment.userId]?.displayName ||
                          "Unknown User"}
                      </span>
                      <span className={styles.postTime}>
                        {formatTimestamp(comment.timestamp)}
                      </span>
                    </div>
                  </Link>

                  {currentUser && comment.userId === currentUser?.uid && (
                    <OverflowMenu options={commentMenuOptions} />
                  )}
                </div>
                <div className={styles.commentBody}>
                  {renderText(
                    comment.commentText || comment.text || "No comment text"
                  )}
                  <LikeButton
                    isLiked={comment.likes?.includes(currentUser?.uid)}
                    onLike={() => handleLike(songId, index, currentUser?.uid)}
                    onUnlike={() =>
                      handleUnlike(songId, index, currentUser?.uid)
                    }
                    likeCount={comment.likes?.length || 0}
                  />
                </div>
              </div>

              {/* Render replies */}
              {comment.replies && comment.replies.length > 0 && (
                <div className={styles.replies}>
                  {comment.replies.map((reply, replyIndex) => {
                    const replyMenuOptions = [
                      {
                        label: "Delete",
                        handler: () => handleOpenConfirmDialog(index, replyIndex),
                        style: { color: "#EF4444" },
                      },
                    ];
                    return (
                      <div className={styles.reply} key={replyIndex}>
                        <div className={styles.userCluster}>
                          <Link
                            to={`/user/${reply.userId}`} 
                            className={styles.userLink}
                            onClick={(e) => {
                              e.preventDefault();
                              handleUserPageNavFromSong(reply.userId);
                            }}
                          >
                            <img
                              src={userAvatars[reply.userId]?.avatar || defaultAvatar}
                              alt={userAvatars[reply.userId]?.displayName || "Unknown User"}
                              className={styles.avatar}
                            />
                            <div className={styles.infoStacked}>
                              <span className={styles.userName}>
                                {userAvatars[reply.userId]?.displayName || "Unknown User"}
                              </span>
                              <span className={styles.postTime}>
                                {formatTimestamp(reply.timestamp)}
                              </span>
                            </div>
                          </Link>
                          {currentUser && reply.userId === currentUser?.uid && (
                            <OverflowMenu options={replyMenuOptions} />
                          )}
                        </div>
                        <div className={styles.replyBody}>
                          {renderText(reply.commentText || reply.text || "No reply text")}
                          <LikeButton
                            isLiked={reply.likes?.includes(currentUser?.uid)}
                            onLike={() => handleLike(songId, index, currentUser?.uid, replyIndex)}
                            onUnlike={() => handleUnlike(songId, index, currentUser?.uid, replyIndex)}
                            likeCount={reply.likes?.length || 0}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
{currentUser ? 

<CommentForm
  currentUser={currentUser}
  addComment={(songId, newComment) => {
    addComment(songId, newComment, index);
  }}
  songId={songId}
  placeholder="Leave a reply"
/> : ''
}
            </div>
          );
        })}
        {showConfirmDialog && (
          <ConfirmDeleteDialog
            show={showConfirmDialog}
            message={selectedReplyIndex !== null ? "Are you sure you want to delete your reply?" : "Are you sure you want to delete your comment?"}
            onClose={() => setShowConfirmDialog(false)}
            confirm={handleConfirmDelete}
          />
        )}
      </div>
    </div>
  );
};

export default CommentList;