import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
  memo,
  useMemo,
} from 'react';
import { useSongs } from '../../config/SongContext';
import { useUser } from '../../config/UserContext';
import CommentForm from '../CommentForm';
import CommentList from '../CommentList';
import LikeButton from '../LikeButton';
import OverflowMenu from '../utils/OverflowMenu';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog/ConfirmDeleteDialog';
import styles from './SongPage.module.scss';
import moment from 'moment';
import Close from '../Icons/Close';
import Player from '../Player';
import Modal from '../utils/Modal';
import UserListItem from '../UserListItem/UserListItem';
import { Helmet } from 'react-helmet';
import { useNavigation } from '../../config/NavigationContext';
import PostFormModal from '../PostFormModal';

const SongPage = memo(
  forwardRef(({ song, onClose, openSongPage }, ref) => {
    const { handleUserPageNav } = useNavigation();
    const [songDetails, setSongDetails] = useState(song);
    const [userDetails, setUserDetails] = useState(null);
    const [isLiking, setIsLiking] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showLikers, setShowLikers] = useState(false);
    const [likersDetails, setLikersDetails] = useState([]);
    const [navbarState, setNavbarState] = useState('initial');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const playerRef = useRef(null);
    const commentFormRef = useRef(null);
    const songInfoRef = useRef(null);

    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth < 768);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleUserPageNavFromSong = useCallback(
      (userId) => {
        if (isMobile) {
          onClose();
        }
        handleUserPageNav(userId);
      },
      [isMobile, onClose, handleUserPageNav]
    );

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setNavbarState('initial');
          } else {
            setNavbarState('visible');
          }
        },
        { threshold: 0.7 }
      );

      const currentSongInfoRef = songInfoRef.current;
      if (currentSongInfoRef) {
        observer.observe(currentSongInfoRef);
      }

      return () => {
        if (currentSongInfoRef) {
          observer.unobserve(currentSongInfoRef);
        }
      };
    }, []);

    const scrollToCommentForm = () => {
      if (commentFormRef.current) {
        commentFormRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'start',
        });
      }
    };

    const {
      getSongById,
      likeSong,
      unlikeSong,
      addComment,
      deleteComment,
      deleteSong,
    } = useSongs();
    const { currentUser, getUserDetailsById } = useUser();

    const likesCount = useMemo(
      () => songDetails.likes?.length || 0,
      [songDetails.likes]
    );
    const isLiked = useMemo(
      () => songDetails.likes?.includes(currentUser?.uid) || false,
      [songDetails.likes, currentUser]
    );

    const commentCount = useMemo(
      () => songDetails.comments?.length || 0,
      [songDetails.comments]
    );

    useEffect(() => {
      const fetchSongDetails = async () => {
        if (song.id) {
          const songData = await getSongById(song.id);
          setSongDetails(songData);
        }
      };

      fetchSongDetails();
    }, [song.id, getSongById]);

    useEffect(() => {
      const fetchUserDetails = async () => {
        if (songDetails && songDetails.userId) {
          const data = await getUserDetailsById(songDetails.userId);
          setUserDetails(data);
        }
      };

      fetchUserDetails();
    }, [songDetails, getUserDetailsById]);

    const handleLike = useCallback(async () => {
      if (currentUser && currentUser.uid && !isLiking) {
        setIsLiking(true);
        await likeSong(songDetails.id, currentUser.uid);
        const updatedSong = await getSongById(songDetails.id);
        setSongDetails(updatedSong);
        setIsLiking(false);
      }
    }, [currentUser, likeSong, getSongById, isLiking]);

    const handleUnlike = useCallback(async () => {
      if (currentUser && currentUser.uid && !isLiking) {
        setIsLiking(true);
        await unlikeSong(songDetails.id, currentUser.uid);
        const updatedSong = await getSongById(songDetails.id);
        setSongDetails(updatedSong);
        setIsLiking(false);
      }
    }, [currentUser, unlikeSong, getSongById, isLiking]);

    const handleAddComment = useCallback(
      async (songId, newComment) => {
        if (currentUser && newComment && newComment.text.trim() !== '') {
          await addComment(songId, newComment);
          const updatedSong = await getSongById(songId);
          setSongDetails(updatedSong);
        }
      },
      [currentUser, addComment, getSongById]
    );

    const handleDeleteComment = useCallback(
      async (commentIndex) => {
        await deleteComment(songDetails.id, commentIndex);
        setSongDetails((prevDetails) => ({
          ...prevDetails,
          comments: prevDetails.comments.filter(
            (_, index) => index !== commentIndex
          ),
        }));
      },
      [deleteComment, songDetails.id]
    );

    const handleDeleteReply = useCallback(
      async (commentIndex, replyIndex) => {
        setSongDetails((prevDetails) => {
          const updatedComments = [...prevDetails.comments];
          const updatedComment = { ...updatedComments[commentIndex] };
          updatedComment.replies = updatedComment.replies.filter(
            (_, index) => index !== replyIndex
          );
          updatedComments[commentIndex] = updatedComment;
          return { ...prevDetails, comments: updatedComments };
        });
      },
      [songDetails.id]
    );

    const handleDelete = () => {
      setShowConfirmDialog(true);
    };

    const confirmDelete = async () => {
      await deleteSong(songDetails.id);
      setShowConfirmDialog(false);
      onClose();
    };

    useEffect(() => {
      if (songDetails && songDetails.likes) {
        const fetchLikersDetails = async () => {
          const userDetailsList = [];
          for (const userId of songDetails.likes) {
            const data = await getUserDetailsById(userId);
            userDetailsList.push(data);
          }
          setLikersDetails(userDetailsList);
        };

        fetchLikersDetails();
      }
    }, [songDetails, getUserDetailsById]);

    const likesAmount = songDetails.likes?.length || 0;
    const isCurrentUser =
      songDetails && currentUser && songDetails.userId === currentUser.uid;

    const renderDescriptionWithLinks = (text) => {
      if (!text) return '';
      const urlPattern = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlPattern, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    };

    const menuOptions1 = [
      {
        label: 'Watch on YouTube',
        handler: () => window.open(songDetails.URL),
      },
    ];
    const menuOptions2 = [
      {
        label: 'Watch on YouTube',
        handler: () => window.open(songDetails.URL),
      },
      { 
        label: 'Edit', 
        handler: () => setIsEditModalOpen(true) 
      },
      { 
        label: 'Delete', 
        handler: handleDelete, 
        style: { color: '#EF4444' } 
      },
    ];

    const MemoizedPlayer = useMemo(() => React.memo(Player), []);

    useImperativeHandle(ref, () => ({
      closePlayer: () => {
        if (playerRef.current && playerRef.current.destroy) {
          playerRef.current.destroy();
        }
      },
    }));

    return (
      <div className={styles.songPage}>
        <Helmet>
          <title>{`${songDetails.songName} by ${songDetails.songArtist} | Good Song Club`}</title>
          <meta
            property='og:title'
            content={`${songDetails.songName} by ${songDetails.songArtist} | Good Song Club`}
          />
          <meta property='og:description' content={songDetails.description} />
          <meta
            property='og:image'
            content={
              songDetails.thumbnailURL
                ? songDetails.thumbnailURL
                : '/logo192.png'
            }
          />
          <meta
            property='twitter:image'
            content={
              songDetails.thumbnailURL
                ? songDetails.thumbnailURL
                : '/logo192.png'
            }
          />
          <meta property='twitter:card' content='summary' />
          <meta
            property='twitter:title'
            content={`${songDetails.songName} by ${songDetails.songArtist} | Good Song Club`}
          />
          <meta
            property='twitter:description'
            content={songDetails.description}
          />
          <meta property='og:url' content={window.location.href} />
          <meta property='twitter:url' content={window.location.href} />
          <meta
            property='og:image:alt'
            content={`Thumbnail for ${songDetails.songName} by ${songDetails.songArtist}`}
          />
          <meta property='og:type' content='website' />
        </Helmet>

        {songDetails ? (
          <div className={styles.songPageContainer}>
            <div className={styles.navBar}>
              <div className={styles.navBarTitle}>
                <div className={`${styles.navBarTitleContent} ${styles[navbarState]}`}>
                  {navbarState === 'initial' ? (
                    <div className={styles.navBarSong}>A Good Song</div>
                  ) : (
                    <>
                      <div className={styles.navBarSong}>{songDetails.songName}</div>
                      <div className={styles.navBarArtist}>{songDetails.songArtist}</div>
                    </>
                  )}
                </div>
              </div>
              <div
                onClick={onClose}
                role='button'
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && onClose()}
                className={styles.closeButton}
              >
                <Close fill='#171717' />
              </div>
            </div>
            <div className={styles.postBody}>
              <div ref={songInfoRef} className={styles.pageHeader}>
                <div className={styles.songInfo}>
                  <h3 className={styles.title}>{songDetails.songName}</h3>
                  <span className={styles.artist}>
                    by {songDetails.songArtist}
                  </span>
                </div>
                <div className={styles.postDetails}>
                  <div
                    onClick={() => handleUserPageNavFromSong(songDetails.userId)}
                    className={styles.userLink}
                  >
                    <img
                      src={userDetails?.avatar}
                      alt={userDetails?.displayName}
                      className={styles.avatar}
                    />
                    <div className={styles.postedByStack}>
                      <span className={styles.postedBy}>
                        {userDetails?.displayName}
                      </span>
                      <span className={styles.postedAt}>
                        {moment(songDetails.timestamp.toDate()).fromNow()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.container}>
                <div className={styles.header}>
                  {songDetails.URL && (
                    <MemoizedPlayer ref={playerRef} Url={songDetails.URL} />
                  )}
                </div>
                <div className={styles.body}>
                  <div className={styles.descriptionSection}>
                    <div className={styles.descriptionBox}>
                      <span className={styles.descriptionBoxLabel}>
                        About this song:
                      </span>
                      <p
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                          __html: renderDescriptionWithLinks(
                            songDetails.description
                          ),
                        }}
                      />
                    </div>
                    {(likesAmount > 0 || commentCount > 0) && (
                      <div className={styles.metadata}>
                        {likesAmount > 0 && (
                          <button
                            onClick={() => setShowLikers(true)}
                            className={styles.metaCount}
                          >
                            {likesAmount} {likesAmount === 1 ? 'like' : 'likes'}
                          </button>
                        )}
                        {commentCount > 0 && (
                          <button
                            onClick={scrollToCommentForm}
                            className={styles.metaCount}
                          >
                            {commentCount} {commentCount === 1 ? 'comment' : 'comments'}
                          </button>
                        )}
                      </div>
                    )}
                    <div className={styles.utilButtons}>
                      <LikeButton
                        isLiked={isLiked}
                        onLike={handleLike}
                        onUnlike={handleUnlike}
                        likeCount={likesCount}
                        disabled={isLiking}
                      />
                      {isCurrentUser ? (
                        <OverflowMenu options={menuOptions2} />
                      ) : (
                        <OverflowMenu options={menuOptions1} />
                      )}
                    </div>
                  </div>
                </div>
                {currentUser && (
                  <CommentForm
                    ref={commentFormRef}
                    currentUser={currentUser}
                    addComment={handleAddComment}
                    songId={songDetails.id}
                    placeholder="Leave a comment"
                  />
                )}
                {showConfirmDialog && (
                  <ConfirmDeleteDialog
                    message='Are you sure you want to delete this song?'
                    show={showConfirmDialog}
                    onClose={() => setShowConfirmDialog(false)}
                    confirm={confirmDelete}
                  />
                )}
              </div>
              {Array.isArray(songDetails.comments) && (
                <CommentList
                  comments={songDetails.comments}
                  currentUser={currentUser}
                  onDelete={handleDeleteComment}
                  onDeleteReply={handleDeleteReply}
                  songId={songDetails.id}
                  ref={commentFormRef}
                  handleUserPageNavFromSong={handleUserPageNavFromSong}
                />
              )}
            </div>
            {showLikers && (
              <Modal
                show={showLikers}
                onClose={() => setShowLikers(false)}
                size='small'
                title="Likes"
              >
                <div className={styles.likersList}>
                  {likersDetails.map((userDetail, index) => (
                    <UserListItem
                      key={index}
                      user={userDetail}
                      handleUserPageNavFromSong={handleUserPageNavFromSong}
                      onCloseModal={() => setShowLikers(false)}
                    />
                  ))}
                </div>
              </Modal>
            )}
            <PostFormModal
              show={isEditModalOpen}
              onClose={() => setIsEditModalOpen(false)}
              isEditing={true}
              postToEdit={songDetails}
            />
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    );
  })
);

export default memo(SongPage, (prevProps, nextProps) => {
  return (
    prevProps.song.id === nextProps.song.id &&
    prevProps.onClose === nextProps.onClose &&
    prevProps.openSongPage === nextProps.openSongPage
  );
});
