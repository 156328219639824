import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  getFirestore,
  query,
  where,
  collection,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const db = getFirestore();
const functions = getFunctions();

const SongsContext = createContext();

export const useSongs = () => {
  return useContext(SongsContext);
};

export const SongsProvider = ({ children }) => {
  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);

  useEffect(() => {
    const fetchSongs = () => {
      const songCollection = collection(db, 'songs');
      const unsubscribe = onSnapshot(songCollection, (snapshot) => {
        const songList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSongs(songList);
      });
      return unsubscribe;
    };

    const unsubscribe = fetchSongs();
    return () => unsubscribe();
  }, []);

  const getSongById = async (songId) => {
    try {
      const songDoc = await getDoc(doc(db, 'songs', songId));
      if (songDoc.exists()) {
        return { id: songDoc.id, ...songDoc.data() };
      } else {
        console.error('No such song!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching song:', error);
      return null;
    }
  };

  const fetchSongById = async (songId) => {
    try {
      const songDoc = await getDoc(doc(db, 'songs', songId));
      if (songDoc.exists()) {
        return { id: songDoc.id, ...songDoc.data() };
      } else {
        console.error('No such song!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching song:', error);
      return null;
    }
  };

  const deleteSong = async (id) => {
    try {
      await deleteDoc(doc(db, 'songs', id));
      setSongs((prevSongs) => prevSongs.filter((song) => song.id !== id));
    } catch (error) {
      console.error('Error deleting song:', error);
    }
  };

  const likeSong = async (songId, userId) => {
    console.log(`Liking song: ${songId} by user: ${userId}`);
    const songRef = doc(db, 'songs', songId);
    const userRef = doc(db, 'users', userId);
  
    try {
      const batch = writeBatch(db);
  
      batch.update(songRef, {
        likes: arrayUnion(userId),
      });
  
      const likedAt = Timestamp.now();
      batch.update(userRef, {
        likedSongs: arrayUnion({ songId, likedAt }),
      });
  
      await batch.commit();
  
      const likeSongNotificationFunction = httpsCallable(functions, 'likeSongNotification');
      console.log('Calling likeSongNotification function with songId:', songId);
      try {
        const result = await likeSongNotificationFunction({ songId });
        console.log('Like notification function result:', result.data);
      } catch (error) {
        console.error('Error calling like notification function:', error);
        if (error.details) {
          console.error('Error details:', error.details);
        }
      }
    } catch (error) {
      console.error('Error liking the song:', error);
    }
  };

  const unlikeSong = async (songId, userId) => {
    const songRef = doc(db, 'songs', songId);
    const userRef = doc(db, 'users', userId);

    try {
      const batch = writeBatch(db);

      batch.update(songRef, {
        likes: arrayRemove(userId),
      });

      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();
      const likedSongObject = userData.likedSongs.find(
        (obj) => obj.songId === songId
      );

      if (likedSongObject) {
        batch.update(userRef, {
          likedSongs: arrayRemove(likedSongObject),
        });
      }

      await batch.commit();
    } catch (error) {
      console.error('Error unliking the song:', error);
    }
  };

  const addComment = async (songId, newComment, parentCommentIndex = null) => {
    console.log(`Adding comment to song: ${songId} by user: ${newComment.userId}`);
    const songRef = doc(db, 'songs', songId);
  
    if (!songId || !newComment.userId || !newComment.displayName || !newComment.text) {
      console.error('Error: One or more required fields are undefined.');
      return;
    }
  
    const commentToAdd = {
      userId: newComment.userId,
      userName: newComment.displayName,  // Ensure the displayName is being passed correctly
      commentText: newComment.text,      // Store the comment text properly
      timestamp: Timestamp.now(),
      likes: [],
      replies: [],
    };
    
  
    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();
  
      if (songData) {
        let updatedComments = songData.comments || [];
  
        if (parentCommentIndex === null) {
          // If no parentCommentIndex is passed, add it as a top-level comment
          updatedComments = [...updatedComments, commentToAdd];
        } else {
          // If there is a parentCommentIndex, treat it as a reply
          const parentComment = updatedComments[parentCommentIndex];
          if (parentComment) {
            parentComment.replies = parentComment.replies || [];
            parentComment.replies.push(commentToAdd);
            updatedComments[parentCommentIndex] = parentComment;
          }
        }
  
        // Update Firestore
        await updateDoc(songRef, { comments: updatedComments });
  
        // Update local state immediately after adding a reply
        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );
  
        console.log('Comment added successfully:', commentToAdd);
      } else {
        console.error('Error: The song does not exist.');
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };
  
  

  const deleteComment = async (songId, commentIndex) => {
    const songRef = doc(db, 'songs', songId);
    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = [...songData.comments];
        updatedComments.splice(commentIndex, 1);
        await updateDoc(songRef, { comments: updatedComments });
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const fetchSongsByUser = async (userId) => {
    try {
      const songsQuery = query(
        collection(db, 'songs'),
        where('userId', '==', userId)
      );
      const querySnapshot = await getDocs(songsQuery);
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error('Error fetching songs by user:', error);
      return [];
    }
  };

  const likeComment = async (songId, commentIndex, userId, replyIndex = null) => {
    const songRef = doc(db, 'songs', songId);
    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();
  
      if (songData && songData.comments) {
        const updatedComments = [...songData.comments];
  
        if (replyIndex === null) {
          // Like a top-level comment
          const comment = updatedComments[commentIndex];
          comment.likes = comment.likes || [];
          comment.likes.push(userId);
          updatedComments[commentIndex] = comment;
        } else {
          // Like a reply to a comment
          const comment = updatedComments[commentIndex];
          const reply = comment.replies[replyIndex];
          reply.likes = reply.likes || [];
          reply.likes.push(userId);
          comment.replies[replyIndex] = reply;
          updatedComments[commentIndex] = comment;
        }
  
        await updateDoc(songRef, { comments: updatedComments });
      }
    } catch (error) {
      console.error('Error liking the comment or reply:', error);
    }
  };
  

  const unlikeComment = async (songId, commentIndex, userId, replyIndex = null) => {
    const songRef = doc(db, 'songs', songId);
    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();
  
      if (songData && songData.comments) {
        const updatedComments = [...songData.comments];
  
        if (replyIndex === null) {
          // Unlike a top-level comment
          const comment = updatedComments[commentIndex];
          const userIndex = comment.likes.indexOf(userId);
          if (userIndex !== -1) comment.likes.splice(userIndex, 1);
          updatedComments[commentIndex] = comment;
        } else {
          // Unlike a reply
          const comment = updatedComments[commentIndex];
          const reply = comment.replies[replyIndex];
          const userIndex = reply.likes.indexOf(userId);
          if (userIndex !== -1) reply.likes.splice(userIndex, 1);
          comment.replies[replyIndex] = reply;
          updatedComments[commentIndex] = comment;
        }
  
        await updateDoc(songRef, { comments: updatedComments });
      }
    } catch (error) {
      console.error('Error unliking the comment or reply:', error);
    }
  };
  

  const value = {
    songs,
    currentSong,
    setCurrentSong,
    setSongs,
    getSongById,
    fetchSongById,
    deleteSong,
    likeSong,
    unlikeSong,
    addComment,
    deleteComment,
    likeComment,
    unlikeComment,
    fetchSongsByUser,
  };

  return (
    <SongsContext.Provider value={value}>{children}</SongsContext.Provider>
  );
};

export default SongsProvider;