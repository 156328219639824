import React, { useState, useEffect, useRef, forwardRef } from 'react';
import styles from './CommentForm.module.scss';
import Send from '../Icons/Send';
import Spinner from '../Spinner/Spinner';

const CommentForm = forwardRef(({ currentUser, addComment, songId, placeholder = 'Add a comment' }, ref) => {
  const [commentText, setCommentText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const textareaRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const generateUniqueId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  const handleAddComment = async () => {
    if (currentUser && commentText.trim() !== '' && !isSubmitting) {
      setIsSubmitting(true);

      const newComment = {
        userId: currentUser.uid,
        displayName: currentUser.displayName,
        text: commentText.trim(),
        commentId: generateUniqueId(), 
      };

      try {
        await addComment(songId, newComment);
        setCommentText('');
        resetTextareaHeight();
        setIsFocused(false);
      } catch (error) {
        console.error("Error adding comment:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const resetTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '40px';
      adjustTextareaHeight();
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '40px';
      const scrollHeight = textarea.scrollHeight;
      textarea.style.height = `${scrollHeight}px`;
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('input', adjustTextareaHeight);
      return () => {
        textarea.removeEventListener('input', adjustTextareaHeight);
      };
    }
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [commentText]);

  return (
    <div className={styles.container}>
      {/* Check if currentUser exists before rendering avatar */}
      {currentUser ? (
        <img
          className={styles.avatar}
          src={currentUser.avatar}  // Accessing avatar safely now
          alt={currentUser.displayName}
        />
      ) : (
        <img
          className={styles.avatar}
          src='/path/to/default/avatar.png'  // Use a default avatar if no user is logged in
          alt='Guest User'
        />
      )}
      <div className={`${styles.textareaWrapper} ${isSubmitting ? styles.submitting : ''}`}>
      <textarea
  ref={textareaRef}
  id="comment-form"
  className={styles.commentForm}
  value={commentText}
  onChange={(e) => setCommentText(e.target.value)}
  onFocus={() => setIsFocused(true)}
  onBlur={() => setIsFocused(false)}
  placeholder={placeholder}
  disabled={isSubmitting || !currentUser} 
></textarea>

        {isSubmitting && <div className={styles.overlay}></div>}
      </div>
      {(isFocused || commentText.trim() !== '') && (
        <div className={styles.buttonContainer}>
          {isSubmitting ? (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <button
              className={styles.postButton}
              onClick={handleAddComment}
              disabled={commentText.trim() === '' || !currentUser}  
            >
              <Send fill={commentText.trim() === '' ? '#999' : '#171717'} />
            </button>
          )}
        </div>
      )}
    </div>
  );
});

export default CommentForm;
